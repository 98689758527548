<template>
  <router-link :to="advertiserRoute">
    <slot> {{ advertiser.name }} </slot>
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex';
import { ACTIVE_CONTEXT } from '@/store/modules/context/keys';

export default {
  name: 'AdvertiserLink',
  props: {
    advertiser: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      contextActive: ACTIVE_CONTEXT,
    }),
    advertiserRoute() {
      return this.contextActive?.baseUrl
        ? {
            name: `${this.contextActive.baseUrl}-edit-advertiser`,
            params: { advertiserId: this.advertiser.id },
          }
        : {};
    },
  },
};
</script>
