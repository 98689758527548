var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "router-link",
    { attrs: { to: _vm.advertiserRoute } },
    [
      _vm._t("default", function () {
        return [_vm._v(" " + _vm._s(_vm.advertiser.name) + " ")]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }